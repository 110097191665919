import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'pages.product.form.step.0.title',
    defaultMessage: 'Welcome to the product/initiative creation form!',
  },
  HOW_TO_TITLE: {
    id: 'pages.product.form.step.0.howto.title',
    defaultMessage: 'How to create a product/initiative',
  },
  HOW_TO_1: {
    id: 'pages.product.form.step.0.howto.body.1',
    defaultMessage: 'Nothing could be easier! Let yourself be guided and fill in your digital product sheet (in only 5 steps).',
  },
  HOW_TO_2: {
    id: 'pages.product.form.step.0.howto.body.2',
    defaultMessage: 'Once finalized, your digital product will be published and visible to everyone!',
  },
  HOW_TO_3: {
    id: 'pages.product.form.step.0.howto.body.3',
    defaultMessage: "Don't worry, all this information can be updated later.",
  },
  COMPLETION_TIME: {
    id: 'pages.product.form.step.0.completion.time',
    defaultMessage: 'Time needed: 10 minutes.',
  },
  HINTS: {
    id: 'pages.product.form.step.0.hints',
    defaultMessage: 'Save time by gathering the following elements before you start:',
  },
  HINTS_LOGO: {
    id: 'pages.product.form.step.0.hints.logo',
    defaultMessage: '- Your digital product <b>logo</b> (in .png or .jpg, less than 6 MB)',
  },
  HINTS_PHOTO: {
    id: 'pages.product.form.step.0.hints.photo',
    defaultMessage: '- A <b>screenshot</b> of the main features of your product (.png or .jpg, less than 10 Mo)',
  },
  HINTS_DESCRIPTION: {
    id: 'pages.product.form.step.0.hints.description',
    defaultMessage: '- <b>Elements needed to describe your product</b> from a user point of view.',
  },
  SAVING: {
    id: 'pages.product.form.step.0.saving',
    defaultMessage: 'Your progress will be automatically saved as draft until you decide to validate and publish it.',
  },
  PREREQUISITES_TITLE: {
    id: 'pages.product.form.step.0.prerequisites.title',
    defaultMessage: 'Prerequisites list',
  },
  PREREQUISITES: {
    id: 'pages.product.form.step.0.prerequisites.body',
    defaultMessage: "Wanna save time? Here's the list of fields to fill in the following form",
  },
  PREREQUISITES_BUTTON: {
    id: 'pages.product.form.step.0.prerequisites.button',
    defaultMessage: 'See all prerequisites',
  },
  NEXT: {
    id: 'pages.product.form.step.0.next',
    defaultMessage: 'Let\'s go!',
  },
});
